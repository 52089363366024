/*
  注册了全局组件
*/

// 在当前页面禁用 eslint 代码检查
/* eslint-disable */

import Vue from 'vue'

// 注册一个全局的面板
import Panel from '../components/Panel.vue'
Vue.component('Panel', Panel)

// 注册一个集团下拉框
import blocSelect from '../components/blocSelect.vue'
Vue.component('blocSelect', blocSelect)

// 注册一个酒店下拉框
import hotelSelect from '../components/hotelSelect.vue'
Vue.component('hotelSelect', hotelSelect)
