<template>
  <div>
    <a-select
      show-search
      :value="value"
      :show="show"
      :placeholder="placeholder"
      style="width: 200px; margin-right: 10px"
      :default-active-first-option="false"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="selectSearch"
      @change="selectChange"
    >
      <a-select-option value="" v-if="show"> 全部集团 </a-select-option>
      <a-select-option v-for="d in showList" :key="d.code">
        {{ d.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      default: '请选择集团',
      type: String
    },
    show: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      value: '',
      showList: []
    }
  },
  created() {
    if (this.$store.state.tenantArray.length !== 0) {
      this.showList = this.$store.state.tenantArray
      const temp = this.$store.state.tenantArray.filter(item => {
        return item.code === this.$store.state.blocValue
      })
      this.value = temp.length !== 0 ? temp[0].name : this.showList[0].name
    }
  },
  methods: {
    selectSearch(value) {
      const temp = this.$store.state.tenantArray.filter(item => {
        return item.name.includes(value)
      })
      if (temp.length === 0) {
        this.showList = this.$store.state.tenantArray
      } else {
        this.showList = temp
      }
      this.$store.commit('showBlocList', temp)
    },
    selectChange(value) {
      this.value = value
      this.$emit('selectChange', value)
      this.$store.commit('blocValue', value)
    }
  }
}
</script>
