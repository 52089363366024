import axios from 'axios'
import qs from 'qs'
import local from '../utils/local.js'
import Store from '../store/index.js'

// 默认请求地址
// axios.defaults.baseURL = 'http://www.jiudianyun.cn'
// 默认请求头格式
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'

// 添加请求拦截器
axios.interceptors.request.use(function(config) {
  const token = local.getCookie('token')
  const callbackKey = local.get('callbackKey')
  if (token || callbackKey) {
    if (config.url.indexOf('auth-login?callback_key') === -1) {
      config.headers.token = token
    }

    config.headers.Accept = 'application/json'
    return config
  } else if (token.length === 0) {
    // window.location.href = `${process.env.VUE_APP_API_AUTHURL}/pms/to-callback.html?callback_url=${process.env.VUE_APP_API_LOCALURL}&to=%E6%99%BA%E6%85%A7%E5%95%86%E5%9F%8E`
  }
})

const judgeToken = async () => {
  const cookieToken = local.getCookie('token')
  const sessionToken = sessionStorage.getItem('token')
  if (cookieToken !== sessionToken) {
    console.log(545645)
    localStorage.clear()
    sessionStorage.setItem('token', cookieToken)
    window.self.location.reload()
    // const data = await loginNoKey()
    // Store.dispatch.saveAuthDetail('saveAuthDetail', data)
    // location.reload()
  }
}

// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    // 处理请求200的操作，默认不需要操作，可直接返回 return 返回正确信息调用接口时可以直接promise then 取到正确信息
    if (response.data.code === 403) {
      alert('没有权限，请联系管理员授权')
    }
    return response
  },
  error => {
    if (!error.response) {
      console.log('网络超时')
      return Promise.resolve(error)
    }
    // 处理状态码操作
    switch (error.response.status) {
      case 400:
        console.log('参数错误')
        break
      case 401:
        alert('Auth认证错误，登录过期，请重新登录！！！')
        local.clear('token')
        window.location.href = `${process.env.VUE_APP_API_AUTHURL}/pms/to-callback.html?callback_url=${process.env.VUE_APP_API_LOCALURL}&to=%E6%99%BA%E6%85%A7%E5%95%86%E5%9F%8E`
        break
      case 500:
        console.log('空指针异常')
        break
      case 501:
        console.log('空指针异常')
        break
      default:
        return Promise.reject(error)
    }
    // return error 返回错误
    return error
  }
)

// 超时处理
axios.defaults.timeout = 10000

// 请求方法
export default {
  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      judgeToken().then(() => {
        axios
          .get(url, { params })
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    })
  },
  getLogin(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, JSON.stringify(params), {
          headers: {
            'Accept-Access-Token-Type': 'delicate',
            Accept: 'application/json'
          }
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  post(url, params) {
    return new Promise((resolve, reject) => {
      judgeToken().then(() => {
        axios
          .post(url, JSON.stringify(params), {
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            }
          })
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    })
  },
  fromPost(url, params) {
    return new Promise((resolve, reject) => {
      judgeToken().then(() => {
        axios
          .post(url, qs.stringify(params), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
          .then(res => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    })
  },
  fromAutoHeader(url, params, headersPar) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers: {
            'Content-Type': 'application/json;charset=utf-8 ',
            tenantId: headersPar
          }
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  uploadForm(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers: {
            token: local.get('token')
            // 'Content-Type': 'multipart/form-data'
          },
          timeout: 5 * 60 * 1000
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
