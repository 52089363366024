import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../layout/Home.vue'

import local from '../utils/local.js'

// 在当前页面禁用 eslint 代码检查
/* eslint-disable */

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: Home,
    children: [
      /* 后台管理 */
      {
        path: '/index',
        meta: { name: '首页' },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/index.vue')
      },
      // 集团商城
      {
        path: '/config',
        meta: { name: '基础配置', roles: 'mall:tenant:info' },
        redirect: '/config/name',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/bloc/baseConfig/index.vue'
          ),
        children: [
          {
            path: '/config/name', // 商城配置
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/bloc/baseConfig/configName.vue'
              )
          },
          {
            path: '/config/classify', // 商品分类配置
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/bloc/baseConfig/classify.vue'
              )
          }
        ]
      },
      {
        path: '/commodity',
        name: '商品管理',
        redirect: '/commodity/man',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/bloc/commodity/index.vue'
          ),
        children: [
          {
            path: '/commodity/man', // 商品管理
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/bloc/commodity/commod.vue'
              )
          },
          {
            path: '/commodity/warehouseMan', // 仓库管理
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/bloc/warehouseManage/warehouse.vue'
              )
          }
        ]
      },
      {
        path: '/warehouse/log', // 仓库日志
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/bloc/warehouseManage/wareLog.vue'
          )
      },
      {
        path: '/commodity/insert', // 新增商品
        name: 'insert',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/bloc/commodity/action/insertGoods.vue'
          )
      },
      {
        path: '/commodity/choose', // 选择商品
        name: 'choose',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/bloc/commodity/action/chooseGoods.vue'
          )
      },
      {
        path: '/commodity/edit', // 编辑商品
        name: 'edit',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/bloc/commodity/action/editGoods.vue'
          )
      },
      {
        path: '/order',
        name: 'orderMan',
        redirect: '/order/man',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/bloc/order/index.vue'
          ),
        children: [
          {
            path: '/order/man',
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/bloc/order/orderManage.vue'
              )
          }
        ]
      },
      {
        path: '/order/action', // 编辑订单
        name: 'orderAction',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/bloc/order/editOrderMan.vue'
          )
      },
      {
        path: '/blocFlow', // 集团商城流水
        name: 'blocFlow',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/bloc/blocMallFlow.vue'
          )
      },
      /* 门店商城 */
      {
        path: '/storeShop',
        meta: { name: '门店商城' },
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/storeShop/index.vue')
      },
      {
        path: '/storeShop/config',
        meta: { name: '基础配置' },
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/storeShop/baseConfig/index.vue'
          ),
        children: [
          {
            path: '/storeShop/config/name', // 商城配置
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/storeShop/baseConfig/configName.vue'
              )
          },
          {
            path: '/storeShop/config/classify', // 商品分类配置
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/storeShop/baseConfig/classify.vue'
              )
          }
        ]
      },
      {
        path: '/commodity',
        name: '商品管理',
        redirect: '/storeShop/commodity/man',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/storeShop/commodity/index.vue'
          ),
        children: [
          {
            path: '/storeShop/commodity/man', // 商品管理
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/storeShop/commodity/commod.vue'
              )
          },
          {
            path: '/storeShop/warehouse', // 仓库管理
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/storeShop/warehouseManage/warehouse.vue'
              )
          }
        ]
      },
      {
        path: '/storeShop/warehouse/log', // 仓库日志
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/storeShop/warehouseManage/wareLog.vue'
          )
      },
      {
        path: '/storeShop/commodity/insert', // 新增商品
        name: 'storeShop/insert',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/storeShop/commodity/action/insertGoods.vue'
          )
      },
      {
        path: '/storeShop/commodity/choose', // 选择商品
        name: 'storeShop/choose',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/storeShop/commodity/action/chooseGoods.vue'
          )
      },
      {
        path: '/storeShop/commodity/edit', // 编辑商品
        name: 'storeShop/edit',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/storeShop/commodity/action/editGoods.vue'
          )
      },
      {
        path: '/storeShop/order',
        name: '订单管理',
        redirect: '/storeShop/order/man',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/storeShop/order/index.vue'
          ),
        children: [
          {
            path: '/storeShop/order/man', // 商品管理
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/storeShop/order/orderManage.vue'
              )
          }
        ]
      },
      {
        path: '/storeShop/order/action', // 门店商城编辑订单
        name: 'action',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/storeShop/order/editOrderMan.vue'
          )
      },
      {
        path: '/storeShopFlow', // 门店商城流水
        name: 'storeShopFlow',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/storeShop/storeShopFlow.vue'
          )
      }
    ]
  },
  {
    path: '/error',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

/* 导航守卫 */
router.beforeEach((to, from, next) => {
  const obj =
    location.href.indexOf('?') !== -1
      ? location.href.split('?')[1].split('=')[1]
      : ''
  if (obj.length > 0 || local.getCookie('token')) {
   //else{

      next()
    // }
  } else {
    const domain = localStorage.getItem('domain')
    if (domain !== 'charmplus.cn') {
      window.location.href = `${local.getHttp()}${domain}/pms/to-callback.html?callback_url=${local.getHttp('mall')}${domain}&to=%E6%99%BA%E6%85%A7%E5%95%86%E5%9F%8E`
    } else {
      window.location.href = `${process.env.VUE_APP_API_AUTHURL}/pms/to-callback.html?callback_url=${process.env.VUE_APP_API_LOCALURL}&to=%E6%99%BA%E6%85%A7%E5%95%86%E5%9F%8E`
    }
  }

  // 路由错误判断
  if (to.matched.length === 0) {
    from.name ? next({ name: from.name }) : next('/')
  } else {  
    next()
  }
  
})


export default router
