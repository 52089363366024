import moment from 'moment'
export function nameFilters(name) {
  // 对名字进行过滤    张三（*三）  张某某（张*某）

  if (typeof name === 'undefined') {
    return name
  } else if (name.length < 2) {
    return name
  } else if (name.length === 2) {
    return new Array(name.length).join('*') + name.substr(-1)
  } else {
    return (
      name.substr(0, 1) + new Array(name.length - 1).join('*') + name.substr(-1)
    )
  }
}

export function idCardFilters(num) {
  // 身份证进行过滤  429004199902024125（42900419********25）

  if (typeof num === 'undefined') {
    return num
  } else if (num.length !== 18) {
    return num
  } else {
    return num.replace(/(\d{8})\d{8}(\d*)/, '$1********$2')
  }
}

export function sexIdcareFilter(num) {
  // 根据身份证判断性别

  if (typeof num === 'undefined') {
    return num
  } else if (num.length !== 18) {
    return num
  } else {
    return num.substr(16, 1) % 2 === 1 ? '男' : '女'
  }
}

export function phoneFilters(num) {
  // 手机号码进行过滤  18641526389  （186****6389）

  if (typeof num === 'undefined') {
    return num
  } else if (num.length !== 11) {
    return num
  } else {
    return num.replace(/(\d{3})\d{4}(\d*)/, '$1****$2')
  }
}

export function textFilter(type, n) {
  // 文本超出后截取

  if (typeof type === 'undefined' || type.length <= n) {
    return type
  } else {
    if (type.length > n) {
      return type.substr(0, n) + '...'
    }
  }
}

export function priceFilter(type, n) {
  // 保留两位小数

  if (typeof type === 'undefined') {
    return type
  } else {
    return '￥' + type.toFixed(n)
  }
}

// 时间转时间戳
export function formatTimestap(data) {
  // 时间
  const timeStamp = new Date(data).getTime()
  return timeStamp
}

// 时间戳转时间
export function formatDate(value) {
  let date = new Date(value)
  date = moment(date).format('YYYY-MM-DD HH:mm:ss')
  return date
}
export function formatDate1(value) {
  let date = new Date(value)
  date = moment(date).format('YYYY.MM.DD')
  return date
}

/**
 * 数字转成汉字
 * @params num === 要转换的数字
 * @return 汉字
 * */
function toChinesNum(num) {
  const changeNum = ['零', '日', '一', '二', '三', '四', '五', '六']
  const unit = ['', '十', '百', '千', '万']
  num = parseInt(num)
  const getWan = temp => {
    const strArr = temp
      .toString()
      .split('')
      .reverse()
    let newNum = ''
    const newArr = []
    strArr.forEach((item, index) => {
      newArr.unshift(
        item === '0' ? changeNum[item] : changeNum[item] + unit[index]
      )
    })
    const numArr = []
    newArr.forEach((m, n) => {
      if (m !== '零') numArr.push(n)
    })
    if (newArr.length > 1) {
      newArr.forEach((m, n) => {
        if (newArr[newArr.length - 1] === '零') {
          if (n <= numArr[numArr.length - 1]) {
            newNum += m
          }
        } else {
          newNum += m
        }
      })
    } else {
      newNum = newArr[0]
    }

    return newNum
  }
  const overWan = Math.floor(num / 10000)
  let noWan = num % 10000
  if (noWan.toString().length < 4) {
    noWan = '0' + noWan
  }
  return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
}

// 字符串转数组
export function strCarr(value) {
  if (value) {
    const arr = value.split(',')
    let str = ''
    arr.forEach(item => {
      str += '周' + toChinesNum(item)
    })
    return str
  }
}

// 判断卡劵规则
export function isFree(value) {
  let str = ''
  if (value.couponTypeString === 'FREE') {
    str = '免房劵'
    return str
  }
  if (value.couponReduceRule) {
    if (Number(value.couponReduceRule.split('-')[0]) !== 0) {
      str = `满${value.couponReduceRule.split('-')[0]}减${
        value.couponReduceRule.split('-')[1]
      }`
    } else {
      str = '无门槛' + ',价值' + value.couponReduceRule.split('-')[1]
    }
    return str
  }
}

// 获取一周的时间
export function getLastWeek() {
  var now = new Date()
  var year = now.getFullYear()
  var month = now.getMonth() + 1 // 0-11表示1-12月
  var day = now.getDate()
  var dateObj = {}
  dateObj.now = year + '-' + month + '-' + day
  if (day - 7 <= 0) {
    // 如果在当月7日之前
    var lastMonthDay = new Date(year, parseInt(month) - 1, 0).getDate() // 1周前所在月的总天数
    if (month - 1 <= 0) {
      // 如果在当年的1月份
      dateObj.last = year - 1 + '-' + 12 + '-' + (31 - (7 - day))
    } else {
      dateObj.last = year + '-' + (month - 1) + '-' + (lastMonthDay - (7 - day))
    }
  } else {
    dateObj.last = year + '-' + month + '-' + (day - 7)
  }
  return dateObj
}

// 获取一个月的时间
export function getLastMonth() {
  var now = new Date()
  var year = now.getFullYear()
  var month = now.getMonth() + 1 // 0-11表示1-12月
  var day = now.getDate()
  var dateObj = {}
  dateObj.now = year + '-' + month + '-' + day
  var nowMonthDay = new Date(year, month, 0).getDate() // 当前月的总天数
  if (month - 1 <= 0) {
    // 如果是1月，年数往前推一年<br>
    dateObj.last = year - 1 + '-' + 12 + '-' + day
  } else {
    var lastMonthDay = new Date(year, parseInt(month) - 1, 0).getDate()
    if (lastMonthDay < day) {
      // 1个月前所在月的总天数小于现在的天日期
      if (day < nowMonthDay) {
        // 当前天日期小于当前月总天数
        dateObj.last =
          year + '-' + (month - 1) + '-' + (lastMonthDay - (nowMonthDay - day))
      } else {
        dateObj.last = year + '-' + (month - 1) + '-' + lastMonthDay
      }
    } else {
      dateObj.last = year + '-' + (month - 1) + '-' + day
    }
  }
  return dateObj
}

// 获取不可用时间列表
export function getUnTime(v) {
  let a = []
  let str = ''
  v.forEach(item => {
    a = item.split('/')
    let date1 = new Date(Number(a[0]))
    let date2 = new Date(Number(a[1]))
    date1 = moment(date1).format('YYYY.MM.DD')
    date2 = moment(date2).format('YYYY.MM.DD')
    str += `、${date1} - ${date2} `
  })
  return str
}
