import req from '../utils/request.js'
const path = '/api/marketing'
const mallPath = '/api/mall'
// /* 获取登录接口 */
export function login(params, domain) {
  return req.getLogin(`${mallPath}/auth-login?callback_key=${params}&domain=${domain}`)
}

export function loginNoKey(domain) {
  return req.getLogin(`${mallPath}/auth-login?domain=${domain}`)
}
