import Vue from 'vue'
import Vuex from 'vuex'
import local from '../utils/local.js'
import { getInfo, login, loginNoKey } from '../api/login.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    flag: true,
    hotelList: '',
    hotelId: '',
    showBlocList: [],
    showHotelList: [],
    blocValue: '',
    hotelValue: '',
    // 集团列表
    tenantArray: [],
    // 酒店列表
    hotelArray: [],
    shopId: '', // 商城分类Id,
    shopIdArr: [], // 商城配置Id
    orderStatusString: null,
    // 门店订单管理
    hotelSelectParentId: null,
    blocSelectParentId: null,
    spinning: true
  },
  mutations: {
    spinning: (state, res) => {
      state.spinning = res
    },
    getTenantArray: (state, res) => {
      state.tenantArray = res
    },
    getHotelArray: (state, res) => {
      state.hotelArray = res
    },
    // 搜索到的集团
    showBlocList(state, res) {
      state.showBlocList = res
    },
    // 当前集团id
    blocValue(state, res) {
      state.blocValue = res
      local.set('blocId', res)
    },
    // 搜索到的酒店
    showHotelList(state, res) {
      state.showHotelList = res
    },
    // 当前酒店id
    hotelValue(state, res) {
      state.hotelValue = res
      local.set('hotelId', res)
    },
    // 获取权限
    hotelPermissions(state, res) {
      // console.log(res)
      state.hotelArray = res
      if (local.get('hotelId')) {
        state.hotelValue = local.get('hotelId')
      } else {
        state.hotelValue = res[0].hotelId
      }
    },
    blocPermissions(state, res) {
      // console.log(res)
      state.tenantArray = res
      if (local.get('blocId')) {
        state.blocValue = local.get('blocId')
      } else {
        state.blocValue = res[0].code
      }
    },
    deptPermissions(state, res) {
      state.createDeptId = res.deptId
      state.createUserName = res.username
      state.userPermissions = res.userPermissions
    }
  },
  actions: {
    /**
     * 处理授权信息
     */
    saveAuthDetail(context, data) {
      if (data.authStatus === 'AUTH-SERVER-20200') {
        if (local.getCookie('token')) {
          sessionStorage.setItem('token', local.getCookie('token'))
        }
        localStorage.setItem('userId', data.authDetail.userId)
        localStorage.setItem('data', JSON.stringify(data))
        localStorage.setItem('domain', data.authDomain)
        local.set('authDetail', JSON.stringify(data.authDetail))
        local.set('token', data.authDetail.token)
        local.set('userName', data.authDetail.username)
        local.set('deptId', data.authDetail.deptId)
        local.set('deptName', data.authDetail.deptName)

        const tenantArray = []
        if (data.authDetail.userAuthorities.tenants.length === 0) {
          tenantArray.push({
            code: data.authDetail.tenantId,
            name: data.authDetail.tenantName
          })
        } else {
          data.authDetail.userAuthorities.tenants.forEach(item => {
            tenantArray.push({
              code: item.split(':')[0],
              name: item.split(':')[1]
            })
          })
        }
        console.log(tenantArray)
        const hotels = data.authDetail.userAuthorities.hotels
        const { hotelArray, tenantId } = local.test(tenantArray, hotels)

        local.set('blocId', tenantId)
        local.set('hotelId', hotelArray[0].code)
        local.set('hArray', JSON.stringify(hotelArray))
        local.set('hotels', JSON.stringify(hotels))
        local.set('tArray', JSON.stringify(tenantArray))

        let userPermissions = data.authDetail.userPermissions
        userPermissions = userPermissions.filter(item => {
          return item.indexOf('marketing') !== -1 || item.indexOf('mall') !== -1
        })
        local.set('userPermissions', JSON.stringify(userPermissions))
        const obj = {
          username: data.authDetail.username,
          deptId: data.authDetail.deptId,
          deptName: data.authDetail.deptName,
          userPermissions
        }
        console.log(hotelArray)
        console.log(tenantArray)
        console.log(obj)
        context.commit('hotelPermissions', hotelArray)
        context.commit('blocPermissions', tenantArray)
        context.commit('deptPermissions', obj)
        context.commit('spinning', false)
        window.self.location.reload()
      }
    },

    async getPermissions(context) {
      //       const callbackKey = local.UrlParm('callback_key')
      //
      //       if (!local.getCookie('token') && callbackKey) {
      //         console.log('callbackKey')
      //         const data = await login(callbackKey)
      //         context.dispatch('saveAuthDetail', data)
      //         return
      //       }
      //
      //       if (!local.getCookie('token')) {
      //         sessionStorage.clear()
      //         localStorage.clear()
      //         window.location.href = `${process.env.VUE_APP_API_AUTHURL}/pms/to-callback.html?callback_url=${process.env.VUE_APP_API_LOCALURL}&to=%E6%99%BA%E6%85%A7%E5%95%86%E5%9F%8E`
      //       }
      //
      //       if (!sessionStorage.getItem('token')) {
      //         console.log(111111)
      //         sessionStorage.setItem('token', local.getCookie('token'))
      //         const data = await loginNoKey()
      //         context.dispatch('saveAuthDetail', data)
      //         return
      //       }
      //
      //       // 如果session里面有token，但是跟session内的token不一致  则重新去获取授权信息
      //       if (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== local.getCookie('token')) {
      //         console.log(22222)
      //         sessionStorage.setItem('token', local.getCookie('token'))
      //         const data = await loginNoKey()
      //         context.dispatch('saveAuthDetail', data)
      //         return
      //       }
      //
      //       if (!local.get('authDetail')) {
      //         const data = await loginNoKey()
      //         context.dispatch('saveAuthDetail', data)
      //         return
      //       }
      //
      //       context.commit('spinning', false)
      // console.log(local.get('token'))
      // console.log(context)

      // if (local.getCookie('token') && !sessionStorage.getItem('token')) {
      //   window.location.href = `${process.env.VUE_APP_API_AUTHURL}/pms/to-callback.html?callback_url=${process.env.VUE_APP_API_LOCALURL}&to=%E6%99%BA%E6%85%A7%E5%95%86%E5%9F%8E`
      //   return
      // }

      if (!local.getCookie('token')) {
        localStorage.setItem('referrer', document.referrer)
        var callbackKey = ''
        if (location.href.split('&').length !== 1) {
          callbackKey = location.href
            .split('&')[location.href.split('&').length - 1]
            .split('#')[0]
            .split('=')[1]
            .split('&')[0]
        } else {
          callbackKey = location.href
            .split('&')[0]
            .split('#')[0]
            .split('=')[1]
            .split('&')[0]
        }
        local.set('callbackKey', callbackKey)
        let data = ''
        if (callbackKey) {
          const hostname = window.location.hostname
          const arr = hostname.split('.')
          const domain = `${arr[1]}.${arr[2]}`
          data = await login(callbackKey, domain)
          context.dispatch('saveAuthDetail', data)
          return
        }

        const domain = localStorage.getItem('domain')
        if (domain !== 'charmplus.cn') {
          window.location.href = `${local.getHttp()}${domain}/pms/to-callback.html?callback_url=${local.getHttp('mall')}${domain}&to=%E6%99%BA%E6%85%A7%E5%95%86%E5%9F%8E`
          sessionStorage.clear()
          localStorage.clear()
        } else {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = `${process.env.VUE_APP_API_AUTHURL}/pms/to-callback.html?callback_url=${process.env.VUE_APP_API_LOCALURL}&to=%E6%99%BA%E6%85%A7%E5%95%86%E5%9F%8E`
        }

        // if (data.authStatus === 'AUTH-SERVER-20200') {

        //           local.set('token', data.authDetail.token)
        //           local.set('userName', data.authDetail.username)
        //           local.set('deptId', data.authDetail.deptId)
        //           local.set('deptName', data.authDetail.deptName)
        //
        //           const tenantArray = []
        //           if (data.authDetail.userAuthorities.tenants.length === 0) {
        //             tenantArray.push({
        //               code: data.authDetail.tenantId,
        //               name: data.authDetail.tenantName
        //             })
        //           } else {
        //             data.authDetail.userAuthorities.tenants.forEach(item => {
        //               tenantArray.push({
        //                 code: item.split(':')[0],
        //                 name: item.split(':')[1]
        //               })
        //             })
        //           }
        //
        //           const hotels = data.authDetail.userAuthorities.hotels
        //           const { hotelArray, tenantId } = local.test(tenantArray, hotels)
        //
        //           local.set('blocId', tenantId)
        //           local.set('hotelId', hotelArray[0].code)
        //           local.set('hArray', JSON.stringify(hotelArray))
        //           local.set('hotels', JSON.stringify(hotels))
        //           local.set('tArray', JSON.stringify(tenantArray))
        //
        //           let userPermissions = data.authDetail.userPermissions
        //           userPermissions = userPermissions.filter(item => {
        //             return item.indexOf('marketing') !== -1 || item.indexOf('mall') !== -1
        //           })
        //           local.set('userPermissions', JSON.stringify(userPermissions))
        //           const obj = {
        //             username: data.authDetail.username,
        //             deptId: data.authDetail.deptId,
        //             deptName: data.authDetail.deptName,
        //             userPermissions
        //           }
        //
        //           context.commit('hotelPermissions', hotelArray)
        //           context.commit('blocPermissions', tenantArray)
        //           context.commit('deptPermissions', obj)
        //           context.commit('spinning', false)
        //           window.self.location.reload()
        // }
      } else {
        const hostname = window.location.hostname
        const arr = hostname.split('.')
        const domain = `${arr[1]}.${arr[2]}`
        if (!sessionStorage.getItem('token')) {
          console.log('loginNoKey')
          const data = await loginNoKey(domain)
          console.log(data)
          context.dispatch('saveAuthDetail', data)
          return
        }
        // 如果session里面有token，但是跟session内的token不一致  则重新去获取授权信息
        if (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== local.getCookie('token')) {
          sessionStorage.setItem('token', local.getCookie('token'))
          const data = await loginNoKey(domain)
          context.dispatch('saveAuthDetail', data)
          return
        }

        if (!local.get('authDetail')) {
          const data = await loginNoKey(domain)
          context.dispatch('saveAuthDetail', data)
          return
        }
        const tenantArray = JSON.parse(local.get('tArray'))
        const obj = {
          username: local.get('userName'),
          deptId: local.get('deptId'),
          deptName: local.get('deptName'),
          userPermissions: JSON.parse(local.get('userPermissions'))
        }

        context.commit('blocPermissions', tenantArray)
        context.commit('hotelPermissions', JSON.parse(local.get('hArray')))
        context.commit('deptPermissions', obj)
        context.commit('spinning', false)
      }
    }
  },
  modules: {}
})
