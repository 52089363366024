<template>
  <div id="components-layout-demo-basic">
    <a-spin
      style="height: 100% !important"
      id="components-layout-demo-basic"
      :spinning="spinning"
    >
      <a-layout>
        <a-layout-sider><Navmenu /></a-layout-sider>
        <a-layout>
          <a-layout-header><Header /></a-layout-header>
          <a-layout-content>
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-spin>
  </div>
</template>

<script>
import Header from '../layout/component/Header'
import Navmenu from '../layout/component/Navmenu'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  components: {
    Header,
    Navmenu
  },
  data() {
    return {
      spinning: true
    }
  },
  beforeCreate() {
    // this.$store.dispatch('getPermissions')
  },
  methods: {
    // 刷新页面
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    }
  },
  computed: {
    getSpinning() {
      return this.$store.state.spinning
    }
  },
  watch: {
    getSpinning: {
      immediate: true,
      handler: function(n) {
        this.spinning = n
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="less">
#components-layout-demo-basic {
  text-align: center;
  height: 100%;
  .ant-layout {
    height: 100%;
  }
  .ant-spin-container {
    height: 100%;
  }
}
#components-layout-demo-basic .ant-layout-header {
  position: fixed;
  top: 0;
  z-index: 999;
  width: calc(100% - 200px);
  padding: 0 5rem;
  background: #101010;
}
#components-layout-demo-basic .ant-layout-sider {
  background: #101010;
  color: #fff;
  width: 20rem;
}
#components-layout-demo-basic .ant-layout-content {
  // background: #ccc;
  // background: #f5f5f5;
  min-height: 12rem;
  margin-top: 64px;
}
#components-layout-demo-basic > .ant-layout {
  margin-bottom: 4.8rem;
}
#components-layout-demo-basic > .ant-layout:last-child {
  margin: 0;
}
.copyright {
  padding: 1rem;
  position: fixed;
  bottom: 0;
  right: 40%;
}
</style>
