import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import XLSX from 'xlsx'

import moment from 'moment'
import 'moment/locale/zh-cn'

// echarts
import ECharts from 'vue-echarts'
import * as echarts from 'echarts'

// cookie
import './utils/components.js'
import cookie from './utils/cookie.js'

import * as filters from './utils/filters.js'
// 全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.prototype.cookie = cookie

// 导出xlsx
Vue.prototype.XLSX = XLSX

moment.locale('zh-cn')

Vue.use(Antd)
Vue.component('chart', ECharts)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
