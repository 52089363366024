/* 封装本地储存的函数 */

export default {
  set(k, v) {
    window.localStorage.setItem(k, v)
  },
  get(k) {
    return window.localStorage.getItem(k)
  },
  remove(k) {
    window.localStorage.removeItem(k)
  },
  clear() {
    window.localStorage.clear()
  },
  UrlParm(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    var r = window.location.search.substr(1).match(reg)
    // if (r != null) return unescape(r[2]);
    if (r != null) return decodeURIComponent(r[2])
    return null
  },
  getCookie(name) {
    const cookieArr = document.cookie.split(';')
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split('=')
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1])
      }
    }
    return null
  },
  test(tenantArray, hotels) {
    const hotelArray = []
    for (let i = 0; i < tenantArray.length; i++) {
      for (const key in hotels) {
        if (tenantArray[i].code === key) {
          // console.log(hotels[key])
          hotels[key].forEach(item => {
            hotelArray.push({
              code: item.split(':')[0],
              name: item.split(':')[1]
            })
          })
          return {
            hotelArray,
            tenantId: tenantArray[i].code
          }
        }
      }
    }
  },
  /**
   * 单独域名跳转区分环境
   */
  getHttp (e) {
    const env = process.env.VUE_APP_MODE

    // 报表
    if (e && e === 'report') {
      if (env === 'development') {
        return 'http://web-reporttest.'
      }
      if (env === 'test') {
        return 'http://web-reporttest.'
      }
      if (env === 'pre') {
        return 'http://web-reportpre.'
      }
      if (env === 'production') {
        return 'https://report.'
      }
    }

    // 茶餐
    if (e && e === 'tea') {
      if (env === 'development') {
        return 'http://web-teatest.'
      }
      if (env === 'test') {
        return 'http://web-teatest.'
      }
      if (env === 'pre') {
        return 'http://web-teapre.'
      }
      if (env === 'production') {
        return 'https://tea.'
      }
    }

    // 酒店帮帮
    if (e && e === 'marketing') {
      if (env === 'development') {
        return 'http://web-marketingtest.'
      }
      if (env === 'test') {
        return 'http://web-marketingtest.'
      }
      if (env === 'pre') {
        return 'http://web-marketingpre.'
      }
      if (env === 'production') {
        return 'https://marketing.'
      }
    }

    // 商城
    if (e && e === 'mall') {
      if (env === 'development') {
        return 'http://web-malltest.'
      }
      if (env === 'test') {
        return 'http://web-malltest.'
      }
      if (env === 'pre') {
        return 'http://web-mallpre.'
      }
      if (env === 'production') {
        return 'https://mall.'
      }
    }

    if (env === 'development') {
      return 'http://imstest.'
    }
    if (env === 'test') {
      return 'http://imstest.'
    }
    if (env === 'pre') {
      return 'http://imspre.'
    }
    if (env === 'production') {
      return 'https://api-ims.'
    }
  }
}
