<template>
  <div class="panel">
    <!-- 1.0 头部的标题 -->
    <div class="panel-header">
      <slot name="header"></slot>
    </div>
    <!-- 2.0 内容的部分 -->
    <div class="panel-con">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.panel {
  height: 100%;
  margin: 0 10px;
  .panel-header {
    margin: 1.2rem 0 1.4rem 0;
    background-color: #fff;
  }
  /deep/.ant-input-group .ant-input {
    float: right;
    width: 30%;
    height: 3.4rem;
    box-shadow: none;
    border: 0.1rem solid #ccc;
  }
  .ant-btn-primary,
  /deep/.ant-input-group .ant-btn-primary {
    background-color: #4e6ef2;
    border: 0.1rem solid #4e6ef2;
    height: 3.4rem;
  }
  .panel-con {
    box-sizing: border-box;
    background-color: #fff;
  }
}
</style>
