<template>
  <div class="aside_wrapper">
    <div class="title" style="color: #fff">智慧零售管理系统</div>
    <div>
      <a-menu
        theme="dark"
        mode="inline"
        :defaultSelectedKeys="defaultSelectedKeys"
        :openKeys="openKeys"
        @openChange="onOpenChange"
      >
        <!-- 集团商城 -->
        <a-sub-menu key="/index" v-if="isShowTenand">
          <span slot="title"><span>集团商城</span></span>
          <a-menu-item key="/index" @click="clickTitle">
            <span>商城授权</span>
          </a-menu-item>
          <a-sub-menu key="/config">
            <span slot="title"><span>基础配置</span></span>
            <a-menu-item key="/config/name" @click="clickTitle">
              商城配置
            </a-menu-item>
            <a-menu-item key="/config/classify" @click="clickTitle">
              商城分类配置
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item key="/commodity/man" @click="clickTitle">
            <span>商品管理</span>
          </a-menu-item>
          <a-menu-item key="/order" @click="clickTitle">
            <span>订单管理</span>
          </a-menu-item>
          <a-menu-item key="/blocFlow" @click="clickTitle">
            <span>集团商城流水</span>
          </a-menu-item>
        </a-sub-menu>
        <!-- 门店商城 -->
        <a-sub-menu key="/storeShop" v-if="isShowHotel">
          <span slot="title"><span>客房服务</span></span>
          <a-menu-item key="/storeShop" @click="clickTitle">
            <span>商城授权</span>
          </a-menu-item>
          <a-sub-menu key="/storeShop/config">
            <span slot="title"><span>基础配置</span></span>
            <a-menu-item key="/storeShop/config/name" @click="clickTitle">
              商城配置
            </a-menu-item>
            <a-menu-item key="/storeShop/config/classify" @click="clickTitle">
              商城分类配置
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item key="/storeShop/commodity/man" @click="clickTitle">
            <span>商品管理</span>
          </a-menu-item>
          <a-menu-item key="/storeShop/order" @click="clickTitle">
            <span>订单管理</span>
          </a-menu-item>
          <a-menu-item key="/storeShopFlow" @click="clickTitle">
            <span>门店商城流水</span>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </div>
  </div>
</template>

<script>
import local from '../../utils/local.js'
export default {
  data() {
    return {
      path: '',
      defaultSelectedKeys: [this.$route.path],
      openKeys: ['/index'],
      subOpenKeys: ['/config'],
      defaultOpenKeys: ['/config'],
      flag: '',
      isShowTenand: true,
      isShowHotel: true
    }
  },
  created() {
    var userPermissions = JSON.parse(local.get('userPermissions'))

    if (userPermissions.indexOf('mall:tenant:info') === -1 || userPermissions.indexOf('ims:smart_mall:group_mall:query') === -1) {
      this.$router.push({
        path: '/storeShop'
      })
    }

    if (userPermissions.indexOf('mall:tenant:info') !== -1 || userPermissions.indexOf('ims:smart_mall:group_mall:query') !== -1) {
      this.isShowTenand = true
    } else {
      this.isShowTenand = false
    }
    if (userPermissions.indexOf('mall:hotel:info') !== -1 || userPermissions.indexOf('ims:smart_mall:store_mall:query') !== -1) {
      this.isShowHotel = true
    } else {
      this.isShowHotel = false
    }

    this.path = this.$route.path
    this.flag = this.$store.state.flag

    this.openKeys = [this.$route.path]
    this.defaultSelectedKeys = [this.$route.path]
    this.defaultOpenKeys = [this.$route.path]

    if (this.$route.path.indexOf('/storeShop') !== -1) {
      this.openKeys = ['/storeShop']
    } else if (this.$route.path === '/commodity/warehouseMan') {
      this.defaultSelectedKeys = ['/commodity/man']
      this.selectedKeys = ['/commodity/man']
      this.openKeys = ['/index']
    } else {
      this.openKeys = ['/index']
    }
    if (this.$route.path.indexOf('/storeShop/warehouse') !== -1) {
      this.defaultSelectedKeys = ['/storeShop/commodity/man']
      this.selectedKeys = ['/storeShop/warehouse']
      this.openKeys = ['/storeShop']
    }
    if (this.$route.path.indexOf('/storeShop/order') !== -1) {
      this.defaultSelectedKeys = ['/storeShop/order']
    }
  },
  methods: {
    clickTitle({ item, key, keyPath }) {
      this.$router.push({
        path: key
      })
    },
    onOpenChange(openKeys) {
      // 当菜单被展开时触发此处
      /*
      经测试传入的变量openKeys是数组 点击已经展开的菜单时传入的是空数组
      点击未展开的菜单时传入的是[当前展开菜单的key,点击的菜单key]
      下面的if判断用openKeys === [] 无效 所以我只能判断数组length是否等于0
      */
      if (openKeys.length !== 0) {
        this.openKeys = openKeys
      } else {
        this.openKeys = ['']
      }
    }
  },
  computed: {
    getFlag() {
      return this.$store.state.flag
    }
  },
  watch: {
    getFlag: {
      deep: true,
      handler: function(newval, oldval) {
        // console.log(11)
        this.flag = newval
        // console.log(this.flag)
        // this.defaultSelectedKeys = ['/storeShop']
      }
    }
  }
}
</script>

<style lang="less" scoped>
.aside_wrapper {
  // width: 20rem;
  .title {
    height: 64px;
    line-height: 64px;
    // background-color: #1e222f;
    color: #8c909f;
    font-size: 18px;
  }

  /deep/.ant-menu-submenu-title {
    padding-left: 0 !important;
  }

  .ant-menu-item {
    padding: 0 6rem;
  }

  .ant-menu-dark {
    background-color: #101010;
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu-item-selected {
    background-color: #2589ff;
    // box-sizing: border-box;
    border-left: 3px solid #fff;
    color: #fff;
    // padding-left: 1.5rem;
  }
}

/deep/.ant-menu-dark.ant-menu-inline .ant-menu-item,
/deep/.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  text-align: left;
  padding-left: 2.4rem !important;
}
/deep/.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #0a0b11;
}
/deep/.aside_wrapper /deep/.ant-menu-item {
  padding: 0 !important;
}
.ant-menu-item {
  padding: 0 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  .ant-menu-item {
    span {
      padding-left: 1rem;
    }
  }
}
/deep/.ant-menu-submenu .ant-menu-submenu-inline {
  .ant-menu > li.ant-menu-item-selected {
    padding-left: 3.6rem !important;
  }
  .ant-menu li.ant-menu-item {
    padding-left: 4.2rem !important;
  }
  .ant-menu-submenu-title {
    padding-left: 3.4rem !important;
  }
}
</style>
