<template>
  <div class="header_wrapper">
    <div class="con">
      <!-- <div>{{ currentTime }}</div> -->
      <!-- <a-select
        showSearch
        :value="value"
        optionFilterProp="children"
        @change="selectChange"
      >
        <a-select-option
          v-for="(item, i) in hotelList"
          :key="i"
          :value="item.code"
          >{{ item.name }}</a-select-option
        >
      </a-select> -->
    </div>
  </div>
</template>

<script>
import { getBlocClassifyList } from '../../api/blocImpower.js'
import local from '../../utils/local.js'
// import { getUsername, EditUsername, pwd } from '../../api/dep.js'
// import { navMenu } from '../../api/price.js'

export default {
  data() {
    return {
      path: '',
      hotelList: [
        {
          name: '全部酒店',
          code: 'all'
        },
        {
          name: '南国灯城-懒洋洋集团',
          code: '0004d6942c7d41d19ebd1e24abe6feac'
        },
        {
          name: '携家曼庭',
          code: '1e186e11e02240f18431da0144aeafbf'
        }
      ],
      timer: '',
      a: '',
      visible: false,
      currentTime: new Date(),
      form: {
        username: '11',
        password: 0,
        newPassword: 0,
        mobile: '',
        email: '',
        nickname: '',
        deptId: ''
      },
      system: [],
      earn: [],
      value: 'all',
      selectData: [],
      treeData: []
    }
  },
  created() {
    this.fn()
    this.a = window.document.querySelector('div')
    // this.getUserInfo()
    var _this = this
    this.timer = setInterval(function() {
      _this.currentTime =
        new Date().getFullYear() +
        '-' +
        (new Date().getMonth() + 1 < 10
          ? '0' + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1) +
        '-' +
        (new Date().getDate() < 10
          ? '0' + new Date().getDate()
          : new Date().getDate()) +
        ' ' +
        (new Date().getHours() < 10
          ? '0' + new Date().getHours()
          : new Date().getHours()) +
        ':' +
        (new Date().getMinutes() < 10
          ? '0' + new Date().getMinutes()
          : new Date().getMinutes()) +
        ':' +
        (new Date().getSeconds() < 10
          ? '0' + new Date().getSeconds()
          : new Date().getSeconds())
    }, 1000)
  },
  methods: {
    fn() {
      this.path = this.$route.path
      if (this.value === 'all') {
        const temp = []
        this.hotelList.forEach(item => {
          item.code !== 'all' ? temp.push(item.code) : ''
        })
        // this.$store.commit('getHotelName', temp)
      }
    },
    onSelect(v) {
      this.form.deptId = v
    },
    async showModal() {
      this.visible = true
      this.treeData = JSON.parse(local.get('list'))
    },
    async handleOk(e) {
      this.visible = false
      // 修改个人信息
      const data = await EditUsername(this.form)
      if (data.code === 0) {
        this.success(data.msg)
        location.reload()
      } else {
        this.$message.error(data.msg)
        // location.reload()
      }
    },
    // 下拉框选中项
    selectChange(v) {
      if (v === 'all') {
        const temp = []
        this.hotelList.forEach(item => {
          item.code !== 'all' ? temp.push(item.code) : ''
        })
        this.$store.commit('getHotelName', temp)
      } else {
        const temp = [v]
        this.$store.commit('getHotelName', temp)
      }
      this.value = v
      // 商品分类配置
      this.$store.state.shopId = v
      // 商城配置
      const arr1 = []
      this.$store.state.shopIdArr = arr1
      arr1.splice(0, arr1.length)
      arr1.push(v)
    },

    logout() {
      local.clear()
      location.reload()
    }
  },
  watch: {
    $route: 'fn'
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
.header_wrapper {
  display: flex;
  justify-content: flex-end;
  color: #fff;
  .til {
    a {
      color: #fff;
      display: inline-block;
      margin-right: 4rem;
      padding: 0 0.5rem;
    }
    .active {
      color: #ff6600;
      border-bottom: 0.4rem solid #ff6600;
    }
  }

  .con {
    display: flex;
    align-items: center;
    .ant-btn-link {
      color: #fff;
    }
    .ant-select {
      margin-left: 2rem;
      height: 32px;
    }
  }
}
/deep/.ant-modal-content {
  /deep/.ant-btn-primary {
    background-color: @baseColor;
    border-color: @baseColor;
    &:hover {
      color: #fff !important;
    }
  }
  /deep/ .ant-btn:hover {
    color: @baseColor;
    border-color: @baseColor;
  }
  .ant-form .ant-form-item .ant-input {
    &:hover,
    .ant-input:focus {
      border-color: @baseColor !important;
    }
  }
}
</style>
