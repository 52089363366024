<template>
  <a-config-provider :locale="locale">
    <div id="app" ref="xxx">
      <router-view v-if="isRouterAlive" />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { userInfo } from './api/baseConfig'

export default {
  provide() {
    return {
      reload: this.reload,
      callbackKey: ''
    }
  },
  data() {
    return {
      locale: zhCN,
      isRouterAlive: true
    }
  },

  methods: {

    // 刷新页面
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    }
  },
  beforeCreate() {
    this.$store.dispatch('getPermissions')

    userInfo().then((data) => {
      console.log(data)
      if (localStorage.getItem('userId') !== 'admin' && data.sysTenant.logoUrl) {
        const link = document.getElementById('headLink')
        if (link) {
          document.head.removeChild(link)
        }
        const linkNew = document.createElement('link')
        linkNew.rel = 'shortcut icon'
        linkNew.href = data.sysTenant.logoUrl
        document.head.appendChild(linkNew)
      }
      console.log(localStorage.getItem('userId'))
    })
  }
}
</script>

<style lang="less">
@baseColor: #4196ff;
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 62.5%;
  ul {
    li {
      list-style: none;
    }
  }
}

#app {
  height: 100%;
  // 模态框
  /deep/.ant-modal {
    width: 650px !important;
    .form_model {
      display: flex;
      flex-wrap: wrap;
    }
    .ant-form-item {
      display: flex;
    }
  }

  .form_model1 {
    display: flex;
    flex-wrap: wrap;
    .ant-form-item {
      width: 50%;
      margin-bottom: 0;
      &:nth-child(5) {
        width: 100%;
      }
    }
  }
  .ant-modal-content {
    .ant-modal-body {
      padding: 30px;
    }
    .ant-modal-footer {
      padding: 30px 16px;
    }
    .ant-form {
      display: flex;
      flex-wrap: wrap;
      .ant-form-item {
        width: 50%;
        .ant-input {
          --antd-wave-shadow-color: #2bb086;
          &:focus {
            border: 1px solid #2bb086;
            box-shadow: none;
          }
        }
      }
    }
  }
  table a {
    color: @baseColor !important;
  }
  span {
    .ant-switch-checked {
      background-color: @baseColor;
    }
  }
}

// 模态框文字描述布局
.dec {
  display: flex;
  width: 100%;
  .ant-form-item {
    width: 50%;
    display: flex;
    margin-bottom: 0;
    .ant-form-item-label {
      width: 20%;
      margin-left: 1.2rem;
    }
  }
}
// 模态框公共布局
.form_model {
  .ant-form-item {
    display: flex;
    // width: 100%;
    .ant-form-item-label {
      width: 20%;
    }
    .ant-form-item-control-wrapper {
      width: 80%;
    }
  }
}

// 模态框按钮颜色
/deep/.ant-modal-content {
  /deep/.ant-btn-primary {
    background-color: @baseColor;
    border-color: @baseColor;
    &:hover {
      color: #fff !important;
    }
  }
  /deep/ .ant-btn:hover {
    color: @baseColor;
    border-color: @baseColor;
  }
}

// 模态框
.ant-form-item-control {
  position: relative;
  span.descNum {
    position: absolute;
    bottom: -1.5rem;
    right: 1.5rem;
    color: rgb(153, 153, 153);
  }
  span.inpNum {
    position: absolute;
    bottom: -10px;
    right: 1.5rem;
    color: rgb(153, 153, 153);
  }
}

// 分页
.ant-pagination {
  display: flex;
  justify-content: flex-end;
  margin: 1.6rem !important;
}
</style>
