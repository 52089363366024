import req from '../utils/request.js'
const path = '/api/mall'
const imsPath = '/api/pms'

/* 获取商品分类列表 */
export function getBlocClassifyList(params) {
  return req.post(`${path}/manage/category/list`, params)
}

/** 当前登录信息接口 */
export function userInfo () {
  return req.get(`${imsPath}/sys/user/info`)
}
