<template>
  <div>
    <a-select
      show-search
      :value="value"
      :show="show"
      :placeholder="placeholder"
      style="width: 200px; margin-right: 10px"
      :default-active-first-option="false"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      :disabled="disabled"
      @dropdownVisibleChange="dropdown"
      @search="selectSearch"
      @change="selectChange"
    >
      <a-select-option value="" v-if="show"> 全部酒店 </a-select-option>
      <a-select-option v-for="d in showList" :key="d.code">
        {{ d.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import local from '../utils/local.js'
export default {
  inject: ['reload'],
  props: {
    placeholder: {
      default: '请选择酒店',
      type: String
    },
    show: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      value: '',
      showList: [],
      index: false
    }
  },
  created() {
    if (this.$store.state.hotelArray.length !== 0) {
      this.showList = this.$store.state.hotelArray
      if (!local.get('hotelId')) {
        local.set('hotelId', this.showList[0].code)
      }
      let temp = []
      temp = this.$store.state.hotelArray.filter(item => {
        return item.code === local.get('hotelId')
      })
      this.value = temp.length !== 0 ? temp[0].name : this.showList[0].name
      this.$store.state.hotelValue = temp.length !== 0 ? temp[0].code : ''
      this.$forceUpdate()
    }
  },
  methods: {
    dropdown() {
      this.showList = this.$store.state.hotelArray
    },
    selectSearch(value) {
      const temp = this.$store.state.hotelArray.filter(item => {
        return item.name.includes(value)
      })
      if (temp.length === 0) {
        this.showList = this.$store.state.hotelArray
      } else {
        this.showList = temp
      }
      this.$store.commit('showHotelList', temp)
    },
    selectChange(value) {
      this.value = value
      this.$emit('selectChange', value)
      this.$store.commit('hotelValue', value)
    },
    getHotelList(newval) {
      this.showList = newval
      if (newval.length === 0) {
        this.value = ''
        this.$emit('selectChange', '')
        return false
      }
      let temp = []
      if (this.index) {
        temp = newval.filter(item => {
          return item.code === this.$store.state.hotelValue
        })
      } else {
        temp = newval.filter(item => {
          return item.code === local.get('hotelId')
        })
        this.index = true
      }
      const tempVal = temp.length !== 0 ? temp[0].code : this.showList[0].code
      if (local.get('hotelId') || this.$store.state.hotelValue) {
        this.$emit('selectChange', tempVal)
        this.$store.commit('hotelValue', tempVal)
      } else {
        this.$emit('selectChange', '')
        this.$store.commit('hotelValue', '')
      }
      this.value = temp.length !== 0 ? temp[0].name : this.showList[0].name
    }
  },
  computed: {
    getBlocVal() {
      return this.$store.state.blocValue
    }
  },
  watch: {
    getBlocVal: {
      deep: true,
      immediate: true,
      handler: function(newval, oldval) {
        const hotels = JSON.parse(local.get('hotels'))
        const hotelArray = []
        for (const i in hotels) {
          if (newval === i) {
            hotels[i].forEach(item => {
              hotelArray.push({
                code: item.split(':')[0],
                name: item.split(':')[1]
              })
            })
          }
        }
        this.$store.commit('hotelPermissions', hotelArray)
        local.set('hArray', JSON.stringify(hotelArray))
        this.getHotelList(hotelArray)
      }
    }
  }
}
</script>
